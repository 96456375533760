<template>
	<div style="padding: 20px">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
				<el-form-item label="用户查询">
					<el-input v-model="searchForm.search" placeholder="请输入用户名/手机号"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" @click="search(1)">查询</el-button>
				</el-form-item>
			</el-form>
			<!-- 操作按钮 -->
			<el-form :inline="true">
				<el-form-item>
					<el-button type="primary" @click="toEdit()">添加咨询</el-button>
					<!-- <el-button type="primary">导出</el-button> -->
				</el-form-item>
			</el-form>
		</div>
		<el-table :data="tableData" v-loading="loading">
			<el-table-column type="index" width="100">
			</el-table-column>
			<el-table-column prop="name" label="姓名"  width="150">
			</el-table-column>
			<el-table-column prop="phone" label="联系方式"  width="150">
			</el-table-column>
			<el-table-column prop="created_at" label="日期"  width="150">
			</el-table-column>
			<el-table-column prop="operater_name" label="记录人"  >
			</el-table-column>
		
			<el-table-column fixed="right" label="操作" width="240">
				<template slot-scope="scope">
					<el-button @click="toEdit(scope.row)" size="mini">编辑</el-button>
						<el-button @click="toDetail(scope.row)" size="mini">查看详情</el-button>
					<el-popconfirm title="确定删除吗？" @confirm="del(scope.row)">
						<el-button slot="reference" size="mini">删除</el-button>
					</el-popconfirm>
				</template>
			</el-table-column>
		


		
		</el-table>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				loading: false, // 表格加载状态
				tableData: [], // 数据
				searchForm: {
					search: '',
					page: 1, // 否 int 页码
					page_size: 10, // 否 int 页脚
				},
				total: 0,
			};
		},
		mounted() {
			// 查询数据
			this.search()
		},
		methods: {
			search() {
				this.loading = true;
				this.$api.tablewx.getCustomerConsultingList(this.searchForm).then(res => {
						this.total = res.data.total;
						this.tableData = res.data.data;
						this.loading = false;
				})
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
				this.search()
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.search()
			},
			toEdit(item) {
				console.log(item)
				if (item) {
					this.$router.push(`/tables/customerOpinion/edit/${item.id}`)
				} else {
					this.$router.push(`/tables/customerOpinion/edit`)
				}
			},
			toDetail(item){
				this.$router.push(`/tables/customerOpinion/Detail/${item.id}`)
			},
			del(item) {
				const loading = this.$loading()
				this.$api.tablewx.customerConsultingDel({
					id: item.id
				}).then(res => {
					loading.close()
					if (res.code == 200) {
						this.$message.success(`操作成功`);
						this.search()
					} else {
						this.$message.error(res.msg);
					}
				}).catch(err => {
					loading.close()
					this.$alert(err);
				});
			},
			
		
		},
	};
</script>

<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		overflow: hidden;
		margin-right: 10px;
	}
</style>
